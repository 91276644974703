<script>
	import LoadingButton from './LoadingButton.svelte';
	import Modal from './Modal.svelte';
	import { IsDev, Platform, UserEmail, IsElectron, Version } from '$lib/store.js';
	import { loadStripe } from '@stripe/stripe-js';
	import { get_sugar, copyTextToClipboard } from '$lib/utils.js';
	import { goto } from '$app/navigation';
	import { onMount } from 'svelte';
	import { getStripeInfo } from '$lib/authapi.js';
	import { page } from '$app/stores';
	import dayjs from 'dayjs';
	onMount(() => {
		let id =
				$page.url.searchParams.id ||
				($page.url.searchParams.get && $page.url.searchParams.get('id')) ||
				false,
			planPrice =
				$page.url.searchParams.planPrice ||
				($page.url.searchParams.get && $page.url.searchParams.get('planPrice')) ||
				false,
			subscriptionSuccess =
				$page.url.searchParams.subscriptionSuccess ||
				($page.url.searchParams.get && $page.url.searchParams.get('subscriptionSuccess')) ||
				false;
		if (id && planPrice) subscriptionHandler(undefined, planPrice, { id });
		if (subscriptionSuccess && !$IsElectron) {
			goto(`${$Version?.app == 'omnipilot' ? 'omnipilot://' : 'magic://'}complete`);
		}
	});
	let isTest = 'test';
	let stripeKey;
	export let redirect = { success: '/profile/plans', cancel: '/profile/plans' };
	export let small;
	let prodEnvs = ['beta.ethi.me', 'capacitor', 'file://'];

	let isLoading;
	let prices = {
		testfounding: 'price_1HZbV8ImjdUjPf7UgdQagYN6',
		testfoundingannual: 'price_1HZbV8ImjdUjPf7UgdQagYN6',
		testcasual: 'price_1H32KAImjdUjPf7UPoqla2We',
		testearly: 'price_1H32KAImjdUjPf7UPoqla2We',
		testearlyannual: 'price_1IRF9iImjdUjPf7UpNqu9HBV',
		testpro: 'price_1HZFd0ImjdUjPf7U8uyfRuOA',
		USD: 'price_1LAfmjImjdUjPf7UOCQ2Ur3k',
		USDannual: 'price_1KzoCYImjdUjPf7Ui5HsM8rz',
		founding: 'price_1HXrLCImjdUjPf7UQZsdabbE',
		foundingannual: 'price_1IRJ1XImjdUjPf7Unt5n3KPn',
		early: 'price_1HXQ4qImjdUjPf7UrflCOeOx',
		earlyannual: 'price_1IRJ0BImjdUjPf7UsOKmVJKX',
		casual: 'price_1H5AHPImjdUjPf7UsepCIdvc',
		pro: 'price_1H4Q5QImjdUjPf7U9dkwVZzp'
	};
	// https://flask-server-rya4g2ukoq-ez.a.run.app/api/v1/oauth/stripe/checkout/?price=price_1HZbV8ImjdUjPf7UgdQagYN6&success=file%3A%2F%2F%2Fonboard%2Fcomplete&cancel=file%3A%2F%2F%2Fonboard%2Fsubscribe
	let error;
	async function subscriptionHandler(event, planPrice, session) {
		if (!$IsDev)
			window.analytics?.track?.('Subscribe Clicked', {
				platform: $Platform,
				duration: annual ? 'annual' : 'monthly',
				location: small ? 'paywall' : 'onboard',
				environment: $IsDev ? 'dev' : 'production',
				email: $UserEmail
			});
		if (!$IsDev) {
			stripeKey = 'pk_live_u65ZL9LLUZbieiNQJrod5kba00tSeLZOeq';
			isTest = false;
		} else {
			stripeKey = 'pk_test_mSJPzi4Kk1alRQrqdcbyhKhV00pyEh1MzL';
		}
		if ($Version.app == 'omnipilot') {
			window.open(
				'https://buy.stripe.com/' +
					planPrice +
					`?${'prefilled_promo_code=OMNITRIAL'}&prefilled_email=` +
					$UserEmail,
				'_blank'
			);
			setInterval(() => {
				getStripeInfo();
			}, 4500);
			return;
		}
		let stripe = await loadStripe(stripeKey);
		$IsDev ? (planPrice = annual ? 'testfoundingannual' : 'testfounding') : '';

		// Request 1, flask-server/api/v1/oauth/stripe/checkout
		isLoading = true;
		session =
			session ||
			(await get_sugar(`/api/v1/oauth/stripe/checkout/`, {
				price: prices[planPrice],
				free: true,
				success:
					(!$IsDev || window.location.href.startsWith('file')
						? 'https://app.' + ($Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com')
						: window.location.origin) +
					redirect?.success +
					'?subscriptionSuccess=true',
				cancel:
					(!$IsDev || window.location.href.startsWith('file')
						? 'https://app.' + ($Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com')
						: window.location.origin) + redirect.cancel
			}));
		if (!session?.id) {
			error = 'No subscription intent found';
			return;
		}
		if ($IsElectron) {
			window.open(`${location.origin}/sub?id=${session.id}&planPrice=${planPrice}`, '_blank');
		} else
			stripe.redirectToCheckout({ sessionId: session.id }).then(function (result) {
				isLoading = false;
				if (result.error) {
					// If `redirectToCheckout` fails due to a browser or network
					// error, display the localized error message to your customer.
					error = result?.error?.message || 'unknown error!';
				}
			});
	}
	let annual = false; //
	export let showFree;
	let showOpenAIModal, openAIKey;
	$: plans = [
		{
			title: 'Lite',
			stripeId: annual ? '28o6p1a9r2Rp0ww8wD' : 'aEU3cP6Xfdw31AAaEG',
			details: ['Autocomplete everywhere', 'Basic-quality models'],
			price: annual ? 90 : 9,
			monthPrice: 9
		},

		{
			title: 'Standard',
			stripeId: annual ? 'cN2bJla9r1Nl6UUcMR' : '5kA14HbdvcrZ3II7st',
			details: [
				'1000 GPT4 or Opus uses',
				'In-context generation',
				'Best models',
				'Priority support'
			],
			price: annual ? 180 : 19,
			monthPrice: 19,
			detailHeader: 'GPT + Claude everywhere'
		},
		{
			title: 'Pro',
			stripeId: annual ? '4gwcNp5TbgIf3IIfZ4' : 'aEUfZB2GZ2Rpcfe8wz',
			details: [
				'10,000 GPT4 or Opus uses',
				'Free AI productivity coaching',
				'Feature prioritization'
			],
			price: annual ? 399 : 49,
			monthPrice: 49,
			detailHeader: 'Everything in Standard, and'
		}
	];
</script>

{#if $Version.app == 'omnipilot'}
	<span
		style="background-image: linear-gradient(
						135deg,
						#f51600 4%,
						#ff0073 19%,
						#9641ff 63%,
						#1e90ff 93%,
						#1e90ff 104%
					);"
		class="reinvert text-lg text-center w-max mx-auto text-white font-medium rounded-lg px-2 py-0.5 mb-4"
	>
		40% off first {annual ? 'year' : '3 months'} + free refunds
	</span>
	<div
		class="text-sm mx-auto w-32 flex justify-between h-12 p-1 mb-4 gap-4 rounded-full border border-gray-800"
		style="background: #00000033;min-width:18rem;"
	>
		<button
			class=" {annual ? 'opacity-60 bg-transparent' : 'pricePicker reinvert'}"
			style="transition: font-size, opacity ease-in-out 0.4s;"
			on:click={() => (annual = false)}
		>
			{'Monthly'}
		</button>
		<button
			class="relative {annual
				? 'pricePicker reinvert'
				: 'text-opacity-60 text-white bg-transparent'}"
			style="transition: font-size ease-in-out 0.4s, opacity ease-in-out 0.4s;"
			on:click={() => (annual = true)}
		>
			{annual ? 'Annual' : 'Annual'}
			<div
				class="absolute left-full text-white text-opacity-100 px-4 top-1/2 -translate-y-1/2 text-xs w-max"
			>
				Save $120 per year!
			</div>
		</button>
	</div>
{/if}

<div
	class="grid gap-2 w-full px-12 max-w-6xl relative mx-auto justify-items-center"
	style="grid-template-columns:repeat(auto-fit,minmax(16rem,1fr));"
>
	{#each $Version.app != 'omnipilot' ? [1] : plans as plan, i}
		<div
			class="pricingCard outer"
			style={i == 1 || $Version.app != 'omnipilot'
				? ''
				: 'filter: saturate(0.7);padding: 0px;transform: scale(0.9);'}
		>
			<div class="pricingCard relative featured-pricing dark:bg-opacity-[0.88] bg-black">
				<!-- <p class="preTitle pink">Become a</p>
		<h3>Founding user</h3> -->
				<!-- <div class="title">Pro</div>
			<div class="-mt-2" style="font-size:1.1rem;opacity:0.9;">For serious productivity</div> -->
				{#if $Version.app != 'omnipilot'}
					<div class="price relative">
						{annual ? '$8.25' : '$15'}
					</div>
					<div class="relative bottom-2" style="font-size:1.1rem;opacity:0.9;">
						per month{annual ? ', billed annually' : ''}
					</div>
					<div
						class="text-sm w-full flex justify-between h-12 mx-auto p-1 mb-4 gap-4 rounded-full border border-gray-800"
						style="background: #00000033;min-width:18rem;"
					>
						<button
							class="  {annual ? 'pricePicker' : 'opacity-60 bg-transparent'}"
							style="transition: font-size ease-in-out 0.4s, opacity ease-in-out 0.4s;"
							on:click={() => (annual = true)}
						>
							{'$99 Annual'}
						</button>
						<button
							class=" {annual ? 'opacity-60 bg-transparent' : 'pricePicker'}"
							style="transition: font-size, opacity ease-in-out 0.4s;"
							on:click={() => (annual = false)}
						>
							{'$15 Monthly'}
						</button>
					</div>
					<div class="text-sm text-center">
						{dayjs().format('MMMM')} deal: 30% off with code
						<b
							on:click={(e) => {
								copyTextToClipboard('NEWJOURNEY');
								e.target.innerText = 'Copied!';
								setTimeout(() => {
									e.target.innerText = 'NEWJOURNEY';
								}, 2000);
							}}
							class="cursor-pointer">NEWJOURNEY</b
						>
					</div>
				{:else}
					<div class="text-base text-center h-0 -mt-4 mb-4">{plan.title}</div>
					<div class="price relative flex items-end justify-between">
						<span style="text-decoration: line-through;" class="text-2xl">{'$' + plan.price}</span
						><span class="ml-3 text-4xl"
							>${(Math.round(100 * plan.price * 0.6) / 100).toFixed(2)}</span
						>
						<span class="text-base opacity-90 pb-1">/ {annual ? 'year' : 'month'}</span>
						<!-- {#if annual}
						<span class="text-sm">(16.6% off monthly price)</span>
					{/if} -->
					</div>

					<p class="text-sm mb-2 text-center h-0 -mt-4">
						{@html !annual
							? 'for 3 months, then'
							: 'Save <b style="font-size:1.1em;">$' +
							  Math.round(plan.monthPrice * 12 - plan.price * 0.6) +
							  '</b> this year vs'} ${plan.monthPrice} / month
					</p>

					<div class="my-2">
						<p class="font-semibold mt-4">{plan.detailHeader || 'Includes'}</p>
						{#each plan.details as detail}
							<p class="text-base mb-1.5 flex items-center gap-2">
								<svg
									stroke="currentColor"
									fill="currentColor"
									stroke-width="0"
									viewBox="0 0 20 20"
									aria-hidden="true"
									class="h-4 w-4 mt-0.5 text-emerald-500"
									height="1em"
									width="1em"
									xmlns="http://www.w3.org/2000/svg"
									><path
										fill-rule="evenodd"
										d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
										clip-rule="evenodd"
									></path></svg
								>
								{detail || 'Activate your Omnipilot subscription'}
							</p>
						{/each}
						{#if i == 0}
							<button
								on:click={() => (showOpenAIModal = true)}
								class="text-center bg-gray-400 bg-opacity-20 opacity-90 hover:opacity-100 mt-2 w-full shadow-none"
								>Or use your own OpenAI Key?</button
							>
						{/if}
					</div>
				{/if}
				<LoadingButton
					bclass="w-full text-2xl py-2 my-2  dark:text-white text-black"
					style="box-shadow:none;	background:#112;margin-top:auto;{i == 1 ||
					$Version.app != 'omnipilot'
						? `background-image: linear-gradient(
			135deg,
			#f51600 4%,
			#ff0073 19%,
			#9641ff 63%,
			#1e90ff 93%,
			#1e90ff 104%
		)`
						: 'color:#defe;border:1.5px solid #def3;'};
		box-shadow: 0 4px 130px 0 rgb(150 163 181 / 12%);"
					onClick={(event) =>
						subscriptionHandler(event, plan.stripeId || (annual ? 'USDannual' : 'USD'))}
					text="Subscribe {annual ? '' : ''}"
				/>
				{#if error}
					<div id="error-message" class="text-red">{error}</div>
				{/if}
				{#if $Version.app != 'omnipilot'}
					<p
						class="mt-6 text-sm opacity-80 font-semibold absolute w-full left-0 bottom-1 text-center"
					>
						Free refunds for 30 days
					</p>
				{:else if i == 0}
					<p
						class="mt-6 text-sm opacity-80 font-semibold absolute w-full left-0 bottom-1 text-center"
					>
						By far the cheapest way to use the best AI
					</p>
				{/if}
			</div>
		</div>
	{/each}
</div>

{#if showOpenAIModal}
	<Modal
		bind:show={showOpenAIModal}
		options={{
			cssText:
				'width:420px;filter:saturate(0.6);background:#181a1c;box-shadow: #fff3 0 1px 32px 2px;'
		}}
	>
		<h3>Enter the OpenAI key you want to use</h3>
		<div class="w-full flex justify-between gap-4 py-4" style="">
			<input
				type="text"
				class="w-3/4 bg-black p-2 rounded-md"
				autofocus
				bind:value={openAIKey}
				on:keydown={(e) => {
					if (e.key == 'Enter' && openAIKey?.includes('sk-')) {
						localStorage.openAIKey = openAIKey;
					}
				}}
				placeholder="OpenAI Key e.g. sk-k3jh152..."
			/>
		</div>
		<LoadingButton
			disabled={!openAIKey?.startsWith('sk-') || openAIKey?.length < 16}
			bclass="w-full text-lg py-2 mb-2  dark:text-white text-black "
			style="box-shadow:none;	background:#112;margin-top:auto;background-image: linear-gradient(
			135deg,
			#f51600 4%,
			#ff0073 19%,
			#9641ff 63%,
			#1e90ff 93%,
			#1e90ff 104%
		);
		box-shadow: 0 4px 130px 0 rgb(150 163 181 / 12%);"
			onClick={(e) => {
				if (openAIKey?.includes('sk-')) {
					localStorage.openAIKey = openAIKey;
					// e.target.innerText = 'Saved';
					// setTimeout(() => {
					// 	e.target.innerText = 'Save';
					// }, 2000);
				} else {
					// e.target.innerText = 'Invalid key';
					// setTimeout(() => {
					// 	e.target.innerText = 'Save';
					// }, 2000);
				}
				subscriptionHandler(e, 'aEU3cP6Xfdw31AAaEG');
			}}
			text="Subscribe for $9 / mo"
		/>
	</Modal>
{/if}

<!-- {#if !showFree}
	<LoadingButton
		style="box-shadow:none;color:white;background:#002;margin-top:auto;font-weight:500;font-size:1rem;
		box-shadow: 0 4px 130px 0 rgb(150 163 181 / 12%);"
		onClick={(event) => goto('/onboard/complete')}
		text="Not ready? Start for free"
	/>
{/if} -->
<style lang="postcss">
	@media (prefers-color-scheme: light) {
		.pricingCard.outer {
			filter: saturate(0.6);
		}
	}

	ul {
		padding-inline-start: 25px;
	}
	/* .preTitle {
		text-align: center;
		margin: 0;
		font-weight: 700;
	} */
	.price {
		font-size: 2rem;
		font-weight: 800;
	}
	/* .blue {
		color: dodgerblue;
	} 
	.pink {
		color: #ff0073;
	}
	*/
	.title {
		font-size: 1.6rem;
		font-weight: 600;
	}
	.pricePicker {
		@apply text-lg brightness-150  bg-opacity-90 underline;
		background-image: linear-gradient(
			135deg,
			#f51600 4%,
			#ff0073 19%,
			#9641ff 63%,
			#1e90ff 93%,
			#1e90ff 104%
		);
	}
	.pricingCard {
		position: relative;
		text-align: left;
		width: 100%;
		display: flex;
		margin: auto 0;
		@apply gap-5;
		max-width: 25rem;
		padding: 2rem 2rem 2.25rem;
		flex-direction: column;
		border-radius: 2.5rem;

		font-size: 0.9rem;
		font-weight: 500;
	}
	.pricingCard.outer {
		padding: 2.5px;
		box-shadow: 0 1px 20px 0 rgb(150 163 171 / 20%);
		background-image: linear-gradient(
			135deg,
			#f51600 4%,
			#ff0073 19%,
			#9641ff 63%,
			#1e90ff 93%,
			#1e90ff 104%
		);
	}
	.pricingCard.featured-pricing {
		z-index: 2;
		border-radius: 2.3rem;
		background-image: none;
	}
</style>
